import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

interface IProps {
  open: boolean;
  handleClose: () => void;
  group: string;
  setGroup: (group: string) => void;
  name: string;
  setName: (name: string) => void;
  email: string;
  setEmail: (email: string) => void;
  password: string;
  setPassword: (password: string) => void;
  confirmPassword: string;
  setConfirmPassword: (confirmPassword: string) => void;
  handleUser: () => void;
  button: string;
}

const userGroup = [
  {
    value: "USER",
    label: "user",
  },
  {
    value: "ADMIN",
    label: "admin",
  },
];

export default function FormDialog({
  open,
  handleClose,
  group,
  setGroup,
  name,
  setName,
  email,
  setEmail,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  handleUser,
  button,
}: IProps) {
  const handleChange: any = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGroup(event.target.value);
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create new user</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            gap: "24px",
          }}
        >
          <Box>
            <TextField
              autoFocus
              margin="dense"
              id="Email"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="dense"
              id="Name"
              label="Name"
              type="name"
              fullWidth
              variant="standard"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              margin="dense"
              select
              fullWidth
              label="Select"
              value={group}
              onChange={handleChange}
              helperText="Please select user group"
              variant="standard"
            >
              {userGroup.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box>
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              variant="standard"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              margin="dense"
              id="name"
              label="Password"
              type="password"
              fullWidth
              variant="standard"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          <Button onClick={handleUser} variant="contained">
            {button}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
