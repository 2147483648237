import { useState } from "react";
import "./styles.scss";
import img from "../images/posting_photo.svg";
import Axios from "axios";
import { useContext } from "react";
import { appContext } from "../../App";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

const Login = () => {
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const Url = "https://api.kekitaan.com";
  const { setAuth } = useContext(appContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [alert, setAlert] = useState(false);
  const [msgError, setMsgError] = useState("");

  const Login = async () => {
    try {
      setLoading(true);
      const response = await Axios({
        method: "POST",
        url: `${Url}/users/login`,
        data: {
          email: Email,
          password: Password,
        },
      });
      setError(response.data.message);
      if (response.data.message === "Login OK") {
        localStorage.setItem("auth", "true");
        setAuth(true);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("group", response.data.user.group);
        localStorage.setItem("name", response.data.user.name);
      }
    } catch (error) {
      console.log(error);
    }
    if (error === "That email is not registered") {
      setAlert(true);
      setMsgError("Email tidak terdaftar");
    }
    if (error === "Password incorrect") {
      setAlert(true);
      setMsgError("Password salah");
    }
    setLoading(false);
  };

  return (
    <section id="sign">
      <div className="container">
        <div className="image-content">
          <img src={img} alt="ini gambar" />
        </div>
        <div className="form-login">
          <h2>Login</h2>
          <form>
            {alert && (
              <Alert variant="outlined" severity="warning">
                {msgError}
              </Alert>
            )}
            <div className="form">
              <input
                type="email"
                placeholder="Email"
                required
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder="Password"
                required
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </form>
          {loading ? (
            <Button variant="contained" disabled>
              <CircularProgress
                size={20}
                sx={{
                  color: "white",
                }}
              />
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              onClick={Login}
              style={{
                cursor: "pointer",
              }}
            >
              Login
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};

export default Login;
