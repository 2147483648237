import Login from "./components/login/Login";
import Main from "./layouts/Main";
import { createContext, useState } from "react";

interface IAuth {
  Auth: boolean;
  setAuth: (value: boolean) => void;
}

const CheckLocalStorage = () => {
  const auth = localStorage.getItem("auth");
  if (auth === "true") {
    return true;
  }
  return false;
};

export const appContext = createContext({} as IAuth);

function App() {
  const [Auth, setAuth] = useState(CheckLocalStorage());

  return (
    <appContext.Provider value={{ Auth, setAuth }}>
      <div className="App">{Auth ? <Main /> : <Login />}</div>
    </appContext.Provider>
  );
}

export default App;
