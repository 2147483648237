import { useState } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import "./style.scss";
import { Data, DataAdmin } from "./data";
import { BiLogOut } from "react-icons/bi";
import { tokens } from "../../theme";
import img from "../images/tampan.png";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { appContext } from "../../App";

interface IProps {
  title: string;
  to: string;
  selected: string;
  setSelected: (title: string) => void;
  icon: JSX.Element;
}

const Item = ({ title, to, selected, setSelected, icon }: IProps) => {
  const active = selected === title;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      routerLink={<Link to={to} />}
      active={selected === title}
      icon={icon}
      style={{
        color: active ? "#fff" : colors.grey[100],
        cursor: "pointer",
        backgroundColor: active ? colors.primary[600] : "transparent",
      }}
      onClick={() => setSelected(title)}
    >
      <Typography> {title}</Typography>
    </MenuItem>
  );
};

export default function Layout() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isColapsed, setIsColapsed] = useState<boolean>(false);
  const [selected, setSelected] = useState("Dashboard");
  const { collapseSidebar } = useProSidebar();
  const navigate = useNavigate();
  const { setAuth } = useContext(appContext);
  const isAdmin = localStorage.getItem("group") === "admin".toLocaleUpperCase();

  const handlerLogout = () => {
    setAuth(false);
    navigate("/");
    localStorage.clear();
  };

  return (
    <Sidebar
      defaultCollapsed={isColapsed}
      backgroundColor={colors.primary[400]}
      style={{
        height: "100vh",
        borderRight: "none",
      }}
    >
      <Menu>
        <MenuItem
          onClick={() => setIsColapsed(!isColapsed)}
          icon={isColapsed ? <MenuOutlinedIcon /> : undefined}
          style={{
            margin: "10px 0 20px 0",
            color: colors.grey[100],
            backgroundColor: colors.primary[400],
          }}
        >
          {!isColapsed && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              ml="15px"
              color={colors.grey[100]}
            >
              <Typography variant="h6" color={colors.grey[100]}>
                Musikin
              </Typography>
              <IconButton onClick={() => collapseSidebar(!isColapsed)}>
                <MenuOutlinedIcon
                  style={{
                    color: colors.grey[100],
                  }}
                />
              </IconButton>
            </Box>
          )}
        </MenuItem>
        {!isColapsed && (
          <Box mb="25px">
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                alt="profile-user"
                width="100px"
                height="100px"
                src={img}
                style={{ cursor: "pointer", borderRadius: "50%" }}
              />
            </Box>
            <Box textAlign="center">
              <Typography
                variant="h3"
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{
                  m: "10px 0 0 0",
                  fontSize: "18px",
                }}
              >
                {localStorage.getItem("group")}
              </Typography>
              <Typography
                variant="h6"
                color={colors.greenAccent[500]}
                sx={{
                  fontSize: "16px",
                }}
              >
                {localStorage.getItem("name")}
              </Typography>
            </Box>
          </Box>
        )}
        {isAdmin
          ? DataAdmin.map((item, index) => {
              return (
                <Item
                  key={index}
                  {...item}
                  selected={selected}
                  setSelected={setSelected}
                />
              );
            })
          : Data.map((item, index) => {
              return (
                <Item
                  key={index}
                  {...item}
                  selected={selected}
                  setSelected={setSelected}
                />
              );
            })}
        <MenuItem
          icon={<BiLogOut />}
          style={{
            backgroundColor: colors.primary[400],
          }}
          onClick={handlerLogout}
        >
          Log out
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}
