import { useContext } from "react";
import { Box, IconButton, useTheme } from "@mui/material";
import { ColorModeContext } from "../theme";
import { LightModeOutlined } from "@mui/icons-material";
import { DarkModeOutlined } from "@mui/icons-material";
import { Person2Outlined } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";

const Topbar = () => {
  const theme = useTheme();
  const ColorMode = useContext(ColorModeContext);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{
        padding: "20px 12px",
      }}
    >
      <IconButton>
        <MenuIcon />
      </IconButton>
      <Box>
        <IconButton onClick={ColorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlined />
          ) : (
            <LightModeOutlined />
          )}
        </IconButton>
        <IconButton>
          <Person2Outlined />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
