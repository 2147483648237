export const TableData = [
  {
    id: 1,
    Judul: "Matahariku",
    Penulis: "Tere Liye",
    Musisi: "agnes monica",
    Penerbit: "Gramedia",
    Tahun: "2019",
  },
  {
    id: 2,
    Judul: "Matahariku",
    Penulis: "Tere Liye",
    Musisi: "agnes monica",
    Penerbit: "Gramedia",
    Tahun: "2018",
  },
  {
    id: 3,
    Judul: "Matahariku",
    Penulis: "Tere Liye",
    Musisi: "agnes monica",
    Penerbit: "Gramedia",
    Tahun: "2019",
  },
  {
    id: 4,
    Judul: "Matahariku",
    Penulis: "Tere Liye",
    Musisi: "agnes monica",
    Penerbit: "Gramedia",
    Tahun: "2019",
  },
  {
    id: 5,
    Judul: "Matahariku",
    Penulis: "Tere Liye",
    Musisi: "agnes monica",
    Penerbit: "Gramedia",
    Tahun: "2017",
  },
];
