import "./style.scss";
import { Box, useTheme, Typography, Button } from "@mui/material";
import { tokens } from "../../theme";
import { useState } from "react";

const Dashboard = () => {
  const abjad = "abcdefghijklmnopqrstuvwxyz".toLocaleUpperCase();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [musisi, setmusisi] = useState("");
  const [song, setsong] = useState("");
  console.log(musisi);

  const handlerReset = () => {
    setmusisi("");
    setsong("");
  };

  return (
    <section id="dashboard">
      <Typography variant="h3">Bo Cord & Me lirik</Typography>
      <div
        className="container-musik"
        style={{
          gap: "24px",
        }}
      >
        <Box
          sx={{
            backgroundColor: colors.primary[400],
            borderRadius: "7px",
            height: "110px",
            padding: "15px 20px",
          }}
        >
          <div className="search-konten">
            <Typography
              variant="h5"
              sx={{
                fontbWeight: "700",
              }}
            >
              Musisi
            </Typography>
            <div className="search-icon">
              <select
                name="select"
                value={musisi}
                onChange={(e) => setmusisi(e.target.value)}
              >
                <option defaultValue={"/"}>Select</option>
                <option value="0-9">0-9</option>
                {abjad.split("").map((letter, index) => {
                  return (
                    <option key={index} value={letter}>
                      {letter}
                    </option>
                  );
                })}
              </select>
              <Button
                variant="contained"
                sx={{
                  cursor: "pointer",
                  backgroundColor: colors.blueAccent[500],
                }}
              >
                Search
              </Button>
            </div>
          </div>
        </Box>
        <Box
          sx={{
            backgroundColor: colors.primary[400],
            borderRadius: "7px",
            height: "110px",
            padding: "15px 20px",
          }}
        >
          <div className="search-konten">
            <Typography
              variant="h5"
              sx={{
                fontbWeight: "700",
              }}
            >
              Musisi
            </Typography>
            <div className="search-icon">
              <select
                name="select"
                value={song}
                onChange={(e) => setsong(e.target.value)}
              >
                <option defaultValue={"/"}>Select</option>
                {abjad.split("").map((letter, index) => {
                  return (
                    <option key={index} value={letter}>
                      {letter}
                    </option>
                  );
                })}
              </select>
              <Button
                variant="contained"
                sx={{
                  cursor: "pointer",
                  backgroundColor: colors.blueAccent[500],
                }}
                disabled
              >
                Search
              </Button>
              <Button
                variant="contained"
                sx={{
                  cursor: "pointer",
                  backgroundColor: colors.blueAccent[500],
                }}
                onClick={handlerReset}
              >
                Reset
              </Button>
            </div>
          </div>
        </Box>
      </div>
    </section>
  );
};

export default Dashboard;
