import {
  AiOutlineHome,
  AiOutlineOrderedList,
  AiOutlineDatabase,
} from "react-icons/ai";
import { BsFillPersonFill, BsPeople } from "react-icons/bs";


export const Data = [
  {
    title: "Dashboard",
    to: "/",
    icon: <AiOutlineHome />,
  },
  {
    title: "Profile",
    to: "/profile",
    icon: <BsFillPersonFill />,
  },
  {
    title: "Crud table",
    to: "/crud-table",
    icon: <AiOutlineOrderedList />,
  },
  {
    title: "Data Scrapping",
    to: "/data-scrapping",
    icon: <AiOutlineDatabase />,
  },
];

export const DataAdmin = [
  {
    title: "Dashboard",
    to: "/",
    icon: <AiOutlineHome />,
  },
  {
    title: "Profile",
    to: "/profile",
    icon: <BsFillPersonFill />,
  },
  {
    title: "Manage Team",
    to: "/users",
    icon: <BsPeople />,
  },
  {
    title: "Crud table",
    to: "/crud-table",
    icon: <AiOutlineOrderedList />,
  },
  {
    title: "Data Scrapping",
    to: "/data-scrapping",
    icon: <AiOutlineDatabase />,
  },
];
