import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Box, Typography, useTheme, Button } from "@mui/material";
import { TableData } from "./DataTable";
import { tokens } from "../../theme";

export default function Listtable() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID" },

    {
      field: "Judul",
      headerName: "Judul",
      cellClassName: "cell-name",
      flex: 1,
    },
    {
      field: "Penulis",
      headerName: "Penulis",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "Musisi",
      headerName: "Musisi",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },

    {
      field: "Penerbit",
      headerName: "Penerbit",
      flex: 1,
    },
    {
      field: "Tahun",
      headerName: "Tahun",
      align: "left",
      renderCell: ({ row: { Tahun } }) => {
        return (
          <Box
            width={"50%"}
            margin="0 auto"
            p="5px"
            display="flex"
            justifyContent="space-evenly"
            sx={{
              backgroundColor:
                Tahun % 2 === 0
                  ? colors.blueAccent[700]
                  : colors.greenAccent[700],
              borderRadius: "4px",
            }}
          >
            <Typography>{Tahun}</Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <div
      style={{
        height: 400,
        width: "100%",
        marginTop: "16px",
        padding: "0 12px",
      }}
    >
      <Button
        variant="contained"
        sx={{
          backgroundColor: colors.greenAccent[400],
        }}
      >
        Submit
      </Button>
      <Box
        m="10.5px 0 0 0"
        height="83vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .cell-name": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },

          "& .MuiDataGrid-footerContainer": {
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-toolbarContainer": {
            backgroundColor: colors.greenAccent[700],
          },
        }}
      >
        <DataGrid
          rows={TableData}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
          checkboxSelection
          onSelectionModelChange={(newSelection) => {
            const newselected = new Set(newSelection);
            const selected = TableData.filter((row) => {
              return newselected.has(row.id);
            });
            console.log(selected);
          }}
        />
      </Box>
    </div>
  );
}
