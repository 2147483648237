import { Box, Container, Typography } from "@mui/material";
import React from "react";

const Profile = () => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h3">Tukang sedang bekerja</Typography>
      </Box>
    </Container>
  );
};

export default Profile;
