import Dashboard from "../components/dashboard/Dashboard";
import Layout from "../components/sidebar/Sidebars";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import Profile from "../components/profile/Profile";
import Listteam from "../components/userList/Listteam";
import Topbar from "../global/Topbar";
import { ColorModeContext, useMode } from "../theme";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import Listtable from "../components/Crudtable/Listtable";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const Main = () => {
  const [mode, setMode] = useMode();
  const queryClient = new QueryClient();

  const isAdmin = localStorage.getItem("group") === "admin".toUpperCase();

  return (
    <QueryClientProvider client={queryClient}>
      <ColorModeContext.Provider value={setMode}>
        <ThemeProvider theme={mode}>
          <CssBaseline />
          <div
            style={{
              display: "flex",
              gap: "20px",
            }}
          >
            <ProSidebarProvider>
              <Router>
                <Layout />
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Topbar />
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    {isAdmin && <Route path="/users" element={<Listteam />} />}
                    <Route path="/data-scrapping" element={<Profile />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/crud-table" element={<Listtable />} />
                  </Routes>
                </Box>
              </Router>
            </ProSidebarProvider>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </QueryClientProvider>
  );
};

export default Main;
