import { createSlice } from "@reduxjs/toolkit";

const auth = createSlice({
  name: "auth",
  initialState: { isLogged: false },
  reducers: {
    login: (state) => {
      state.isLogged = true;
    },

    logout: (state) => {
      state.isLogged = false;
    },
  },
});

export const isAuth = auth.actions;
export default auth;
