import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Box, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../../theme";
import {
  AdminPanelSettingsOutlined,
  LockOpenOutlined,
} from "@mui/icons-material";
import useGetUser from "../../Hooks/useGetUser";
import FormDialog from "../dialog/FormsDIalog";
import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Axios from "axios";
import Alert from "@mui/material/Alert";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";

export default function Listteam() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Url = "https://api.kekitaan.com/users";
  const { data: userData, loading } = useGetUser({ api: Url });
  const isAdmin = localStorage.getItem("group");
  const [open, setOpen] = useState(false);
  const [group, setGroup] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [alert, setAlert] = useState(false);
  const [openedit, setOpenedit] = useState(false);
  const [Userid, setId] = useState("");

  const handleClose = () => {
    setOpen(false);
    setOpenedit(false);
  };

  const handleUser = async () => {
    try {
      setLoader(true);
      const response = await Axios.post(Url, {
        name: name,
        password: password,
        email: email,
        group: group,
        password2: confirmPassword,
      });
      if (response.status === 1) {
        setAlert(true);
      }
    } catch (error) {
      console.log(error);
    }
    setOpen(false);
    setLoader(false);
    setAlert(false);
    setName("");
    setPassword("");
    setEmail("");
    setGroup("");
    setConfirmPassword("");
  };

  const handleEditUser = (id: any) => {
    setOpenedit(true);
    setId(id);
  };

  const EditUser = async () => {
    try {
      setLoader(true);
      await Axios({
        method: "PUT",
        url: `https://api.kekitaan.com/users/${Userid}`,
        data: {
          name: name,
          password: password,
          email: email,
          group: group,
          password2: confirmPassword,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    } catch (error) {
      console.log(error);
    }
    setOpenedit(false);
    setLoader(false);
    setName("");
    setPassword("");
    setEmail("");
    setGroup("");
    setConfirmPassword("");
  };

  const columns: GridColDef[] = [
    { field: "_id", headerName: "ID", flex: 1 },

    {
      field: "name",
      headerName: "Name",
      cellClassName: "cell-name",
      flex: 1,
    },

    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "group",
      headerName: "Position",
      align: "left",
      flex: 1,
      renderCell: ({ row: { group } }) => {
        return (
          <Box
            width={"70%"}
            margin="0 auto"
            p="5px"
            display="flex"
            justifyContent="space-evenly"
            sx={{
              backgroundColor:
                group === "Admin".toLocaleUpperCase()
                  ? colors.greenAccent[600]
                  : colors.greenAccent[700],
              borderRadius: "4px",
            }}
          >
            {group === "Admin".toLocaleUpperCase() ? (
              <AdminPanelSettingsOutlined />
            ) : (
              <LockOpenOutlined />
            )}

            <Typography>{group.toLocaleUpperCase()}</Typography>
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: ({ row: { _id } }) => {
        return (
          <Box>
            <IconButton aria-label="Edit" onClick={() => handleEditUser(_id)}>
              <EditIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <div
      style={{
        height: 400,
        width: "100%",
        padding: "0 12px",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {alert && <Alert severity="success">Create account succed</Alert>}
      {isAdmin === "admin".toLocaleUpperCase() && (
        <Button
          onClick={() => setOpen(true)}
          variant="contained"
          sx={{
            cursor: "pointer",
            backgroundColor: colors.blueAccent[500],
          }}
        >
          Add a user
        </Button>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {open && (
        <FormDialog
          open={open}
          handleClose={handleClose}
          group={group}
          setGroup={setGroup}
          name={name}
          setName={setName}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          handleUser={handleUser}
          button="Add"
        />
      )}
      {openedit && (
        <FormDialog
          open={openedit}
          handleClose={handleClose}
          group={group}
          setGroup={setGroup}
          name={name}
          setName={setName}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          handleUser={EditUser}
          button="Edit"
        />
      )}
      <Box
        m="19.5px 0 0 0"
        height="88vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .cell-name": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },

          "& .MuiDataGrid-footerContainer": {
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-toolbarContainer": {
            backgroundColor: colors.greenAccent[700],
          },
        }}
      >
        <DataGrid
          getRowId={(row) => row._id}
          rows={userData}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </Box>
    </div>
  );
}
